const baseUrl = process.env.REACT_APP_BASE_URL;
const fileUrl = process.env.REACT_APP_MEDIA_URL;
let spotifyClientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID
let spotifyAuthEndpoint = process.env.REACT_APP_SPOTIFY_AUTH_URL
const firebaseServerKey = `AAAAu0iUuU4:APA91bETxTMZvXzox0J-Ie58nACx6KVgd5B8ZzAJmRND4sOx1iXJaNJ44EtYilnZMXSD6P8I-O5sK7Tf56oPPiQD6Tv3HwfBJKNTKA8-RnxKcPbvRLx12eqcdbfYlZ0JzsqK0qv-NY6Z`;
const firebaseApiKey = `BN5QRxkw8-edzKpnFItsEaMzE7Wt4yri6mETDrp4HqqpduF-HGWCv0FAYBMoIwKBtdnHolsB0oLMmseNB8Sm-vk`

export {
    baseUrl,
    fileUrl,
    firebaseServerKey,
    firebaseApiKey,
    spotifyClientId,
    spotifyAuthEndpoint
};