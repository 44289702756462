import { Suspense, lazy, useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";

import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import SuspenseLoader from "./utils/SuspenseLoader";
import MyToast from "./utils/MyToast";

import { requestNotificationPermission } from "./utils/const";
import { Helmet } from "react-helmet";
import { StaticImage } from "./utils/images";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  console.log("i18n.language", i18n.language)
  const AppRouter = lazy(() => import("./Routes"));

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    const requestPermissionWithUserGesture = () => {
      if ('safari' in window && 'pushNotification' in window.safari) {
        const permissionData = window.safari.pushNotification.permission('com.mywaymassage.dev');
        console.log("Initial permission data: ", permissionData);

        if (permissionData.permission === 'default') {
          console.log("Requesting permission...");

          try {
            window.safari.pushNotification.requestPermission(
              'https://dev.mywaymassage.com', // Your website URL
              'com.mywaymassage.dev', // Your website push ID
              {}, // Optional parameter object if needed
              function (newPermissionData) {
                console.log("New permission data: ", newPermissionData);

                if (newPermissionData.permission === 'granted') {
                  // Handle token here
                } else if (newPermissionData.permission === 'denied') {
                  console.log('Permission denied');
                } else {
                  console.log('Unexpected permission status:', newPermissionData.permission);
                }
              }
            );
            console.log("Permission request sent.");
          } catch (error) {
            console.error("Error during requestPermission: ", error);
          }
        } else if (permissionData.permission === 'granted') {
          console.log("Permission already granted.");
          // Handle token here
        } else if (permissionData.permission === 'denied') {
          console.log("Permission already denied.");
        } else {
          console.log("Unexpected initial permission status: " + permissionData.permission);
        }
      } else {
        console.log("Safari Push Notifications are not supported in this browser.");
      }
    };
    requestPermissionWithUserGesture()
  }, []);


  /* Handling Net Status & Visible Title */

  // Function to handle visibility change
  const handleVisibilityChange = () => {
    if (document.hidden) {
      document.title = "My Way Massage";
    } else {
      document.title = "My Way Massage";
    }
  };

  useEffect(() => {
    const handleOnline = () => {
      MyToast("s", "Network connection restored!", "top-center");
    };

    const handleOffline = () => {
      MyToast("w", "Network connection lost!", "top-center");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Way Massage || Website</title>
        <link rel="canonical" href={StaticImage.logo.blueLogo} /> {/* Change this Url as per your project */}
        <meta property="og:image" content={StaticImage.logo.blueLogo} /> {/* Change this Url as per your project */}
        <meta name="description" content="My Way Massage" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {`
        {
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          "name": "My Way Massage",
          "url": "https://dev.mywaymassage.com/",
          "logo": "https://dev.mywaymassage.com/logo.png",
          {/* "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-800-555-5555",
            "contactType": "Customer Service"
          } */}
        }
        `}
        </script>
      </Helmet>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback={<SuspenseLoader />}>
            <ErrorBoundary>
              <AppRouter />
            </ErrorBoundary>
          </Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;