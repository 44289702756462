import { USER_DETAIL, JWT_TOKEN, REFRESH_TOKEN, COMMON_CONTENT, LOGIN_TYPE, SPA_LOGIN_FLOW, FCM_TOKEN, ACTIVE_SPA_HOME, CUSTOMIZING_DETAIL, BOOK_MASSAGE, GIFT_DETAIL, COMMON_KEY } from "../types";

const INITIAL_STATE = {
    jwtToken: null,
    commonKey: null,
    fcmToken: null,
    userDetail: null,
    loginType: 'user',
    refreshToken: null,
    commonData: null,
    spaLoginFlow: null,
    activeSpaHome: 1,
    bookMassage: {},
    customizing: null,
    giftDetail:{}
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case JWT_TOKEN:
            return {
                ...state,
                jwtToken: payload,
            };
        case COMMON_KEY:
            return {
                ...state,
                commonKey: payload?.payload,
            };
        case FCM_TOKEN:
            return {
                ...state,
                fcmToken: payload,
            };
        case COMMON_CONTENT:
            return {
                ...state,
                commonData: payload,
            };
        case GIFT_DETAIL:
            console.log("action.payload?.payload", payload);
            return {
                ...state,
                giftDetail: payload?.payload,
            };
        case BOOK_MASSAGE: {
            let currentData = { ...state.bookMassage, ...action.payload?.payload }; // Merge existing data with new data
            if (action.payload?.payload == null) {
                currentData = action.payload?.payload; // Merge existing data with new data
            } else {
                currentData = { ...state.bookMassage, ...action.payload?.payload }; // Merge existing data with new data
            }
            return {
                ...state,
                bookMassage: currentData,
            };
        }
        case SPA_LOGIN_FLOW:
            return {
                ...state,
                spaLoginFlow: payload,
            };
        case ACTIVE_SPA_HOME:
            return {
                ...state,
                activeSpaHome: payload,
            };
        case LOGIN_TYPE:
            return {
                ...state,
                loginType: payload?.payload,
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: payload,
            };
        case CUSTOMIZING_DETAIL:
            return {
                ...state,
                customizing: payload,
            };
        case USER_DETAIL:
            if (payload) {
                return {
                    ...state,
                    userDetail: payload?.payload,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};
